<template>
    <section>
        <table class="table compact startup-table">
            <thead>
                <tr>
                    <th>{{ $t('startup.label_token') }}</th>
                    <th class="d-none d-md-table-cell">{{ $t('startup.orders_start_time') }}</th>
                    <th class="d-none d-md-table-cell">{{ $t('startup.orders_end_time') }}</th>
                    <!-- <th class="d-none d-md-table-cell">{{ $t('startup.label_grant_date') }}</th> -->
                    <th v-if="ongoing_tokens" class="text-center">{{ $t('startup.label_progress') }}</th>
                    <th>{{ $t('startup.label_target_amount') }}</th>
                    <th>{{ $t('startup.label_offer_price') }}</th>
                    <th :class="ongoing_tokens ? 'd-none d-md-table-cell' : ''">{{ $t('startup.label_min_sub') }}</th>
                    <th v-if="ongoing_tokens"></th>
                </tr>
            </thead>
            <tbody v-if="tokens">
                <tr v-for="token in tokens" :key="token.id">
                    <td>
                        <div class="d-flex align-items-center">
                            <div v-if="token.svgIcon" class="pe-2">
                                <startup-icon :token="token" />
                            </div>
                            <div>{{ token.token }}</div>
                        </div>
                    </td>
                    <td class="d-none d-md-table-cell">{{ new Date(token.startTime).formatDateTime() }}</td>
                    <td class="d-none d-md-table-cell">{{ new Date(token.endTime).formatDateTime() }}</td>
                    <!-- <td class="d-none d-md-table-cell">{{ new Date(token.grantTime).formatDateTime() }}</td> -->
                    <td v-if="ongoing_tokens">
                        <!-- <div class="startup-progress">
                            <div class="startup-progress-bar" :style="'width: ' + Math.min(100, (token.subscribedAmount / token.targetAmount) * 100) + '%'"></div>
                            <div>{{ ((token.subscribedAmount / token.targetAmount) * 100).toFixed(2) }}%</div>
                        </div> -->
                        <token-progress :percentage="token.subscribedAmount / token.targetAmount" />
                        <!-- <span>{{ token.subscribedAmount }}</span> -->
                    </td>
                    <td>{{ token.targetAmount }}</td>
                    <td>{{ token.offerPrice.toFixed(token.pricePrecision) }}</td>
                    <td :class="ongoing_tokens ? 'd-none d-md-table-cell' : ''">{{ token.minSubscriptionAmount }}</td>
                    <td v-if="ongoing_tokens">
                        <router-link :to="'/startup/' + token.token.toLowerCase()" class="btn btn-primary">{{ $t('startup.label_subscribe_sm') }}</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <loading-indicator v-if="!tokens" />
        <template v-else>
            <div v-if="tokens.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </template>
    </section>
</template>

<style scoped>
/* .startup-progress {
    display: flex;
    height: 1.5rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    position: relative;
    text-align: center;
}
.startup-progress > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 100%;
    line-height: 1.5rem;
}
.startup-progress-bar {
    background: var(--up-hover);
} */

.startup-table td {
    vertical-align: middle;
    text-align: center;
}
.startup-table {
    text-align: center;
}

</style>

<script>
import StartupIcon from '../../Components/_StartupIcon.vue';
import TokenProgress from './_Progress.vue';

export default {
    components: { StartupIcon, TokenProgress },
    props: ['ongoing_tokens'],

    data() {
        return {
            tokens: null
        };
    },

    watch: {
        /**
         * Update token list when the ongoing_tokens property is updated.
         */
        ongoing_tokens: function () {
            this.tokens = null;
            this.getTokensAsync();
        }
    },

    mounted() {
        this.getTokensAsync();
    },

    methods: {
        getTokensAsync: async function () {
            const self = this;
            const json = await $.callGetApi(self, '/api/v1/startup/' + (this.ongoing_tokens ? 'ongoing' : 'forecast'));
            if (json && json.errcode === 0) {
                self.tokens = json.data;

                Vue.nextTick(() => {
                    $.scanPopup();
                });
            }
        }
    }
};
</script>